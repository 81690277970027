import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { reactive, onMounted, ref } from 'vue';
import { BaseText, BaseButton, BaseSpacer, BaseAlert } from '@/components/partials';
import { BaseSection, BaseContainer, BaseGrid, BaseColumn } from '@/components/primitives';
import { BaseForm, BaseInput } from '@/components/partials/forms';
import { ProductCard, ProductList } from '@/components/features';
import { Modal } from '@/components/containers';
import { HelperFormErrors } from '@/helpers/form';

import { products } from '@/dummy-data/products';

interface MyForm {
  firstName: string;
  lastName: string;
  message: string;
  errors: HelperFormErrors;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Example',
  setup(__props) {

const form: MyForm = reactive({
  firstName: '',
  lastName: '',
  message: '',
  errors: new HelperFormErrors(),
});

const stockModalState = ref('open');

const closeStockModal = () => (stockModalState.value = 'closed');

onMounted(() => {
  form.errors.record({
    'first-name': ['This is an error'],
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(BaseSection), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseContainer), null, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createTextVNode("Light - Gutter default")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseSection), {
      color: "light",
      gutter: "8"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseContainer), null, {
          default: _withCtx(() => _cache[14] || (_cache[14] = [
            _createTextVNode("Light - Gutter 8")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseSection), {
      color: "success",
      gutter: "2"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseContainer), null, {
          default: _withCtx(() => _cache[15] || (_cache[15] = [
            _createTextVNode("Success - Gutter 2")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseSection), { color: "warning" }, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseContainer), null, {
          default: _withCtx(() => _cache[16] || (_cache[16] = [
            _createTextVNode("Warning - Gutter default")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseSection), { color: "warning-alt" }, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseContainer), null, {
          default: _withCtx(() => _cache[17] || (_cache[17] = [
            _createTextVNode("Warning-alt - Gutter default")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseSection), { color: "danger" }, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseContainer), null, {
          default: _withCtx(() => _cache[18] || (_cache[18] = [
            _createTextVNode("Danger - Gutter default")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseContainer), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: '3xl' }
        }, {
          default: _withCtx(() => _cache[19] || (_cache[19] = [
            _createTextVNode("Notes")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[20] || (_cache[20] = [
            _createTextVNode("Spacers - Spacing scale")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), { tag: "p" }, {
          default: _withCtx(() => _cache[21] || (_cache[21] = [
            _createTextVNode(" Spacing scale works on the basis that a whole number is equal to that times one rem, e.g. '1' == 1 rem (16px), '0-5' == 0.5rem (8px) plus helpers like 'hairline' (1px). Spacing scale is relevent for Spacers and grid gaps (spacers between columns). ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[22] || (_cache[22] = [
            _createTextVNode("Text - sizing scale")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), { tag: "p" }, {
          default: _withCtx(() => _cache[23] || (_cache[23] = [
            _createTextVNode(" Text sizes go up 0.2 rem increments from 'sm': 0.8rem - '4xl': '2.2rem', text will default to 'base': 1rem ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[24] || (_cache[24] = [
            _createTextVNode("Grids")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), { tag: "p" }, {
          default: _withCtx(() => _cache[25] || (_cache[25] = [
            _createTextVNode(" Grids work on a 12 column grid, by default they will have a gap of spacing scale size 1. Columns will span 12 columns by default, you may pass an object with default / tablet keys specifying alternative column spans for each breakpoint. ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[26] || (_cache[26] = [
            _createTextVNode("Colors")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), { tag: "p" }, {
          default: _withCtx(() => _cache[27] || (_cache[27] = [
            _createTextVNode(" Colors will be set to default unless otherwise specified, allowed action colors are: success, warning, warning-alt, danger and default. Most components will accept a color. ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[28] || (_cache[28] = [
            _createTextVNode("Imports")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), { tag: "p" }, {
          default: _withCtx(() => _cache[29] || (_cache[29] = [
            _createTextVNode(" Imports have been placed in index files at the route of their respecive directories, to save on the amount of seperate Imports e.g. ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseText), { tag: "p" }, {
          default: _withCtx(() => _cache[30] || (_cache[30] = [
            _createTextVNode(" import BaseText from '@/components/partials/BaseText/BaseText.vue';"),
            _createElementVNode("br", null, null, -1),
            _createTextVNode(" import BaseButton from '@/components/partials/BaseButton/BaseButton.vue';"),
            _createElementVNode("br", null, null, -1),
            _createTextVNode(" import BaseImage from '@/components/partials/BaseImage/BaseImage.vue';"),
            _createElementVNode("br", null, null, -1),
            _createElementVNode("br", null, null, -1),
            _createTextVNode(" Becomes "),
            _createElementVNode("br", null, null, -1),
            _createTextVNode(" import { BaseText, BaseButton, BaseImage } from '@/components/partials' ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseContainer), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: '3xl' }
        }, {
          default: _withCtx(() => _cache[31] || (_cache[31] = [
            _createTextVNode("Modals")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(Modal), {
          title: "Controlled by component",
          "open-button-text": "Open",
          color: "danger"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseText), { tag: "p" }, {
              default: _withCtx(() => _cache[32] || (_cache[32] = [
                _createTextVNode(" The state of this modal is controlled by the component and has an open / close button ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer), { size: "2" }),
        _createVNode(_unref(BaseText), { tag: "p" }, {
          default: _withCtx(() => _cache[33] || (_cache[33] = [
            _createTextVNode("NOTE: The modal that popped up at the start is hidden here")
          ])),
          _: 1
        }),
        _createVNode(_unref(Modal), {
          title: "Controlled by parent",
          "parent-state": stockModalState.value
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseText), { tag: "p" }, {
              default: _withCtx(() => _cache[34] || (_cache[34] = [
                _createTextVNode(" If parent state is present it'll take presidence over the component state, the buttons below call a function in thre parent ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseSpacer), { size: "2" }),
            _createVNode(_unref(BaseButton), {
              color: "danger",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (closeStockModal()))
            }, {
              default: _withCtx(() => _cache[35] || (_cache[35] = [
                _createTextVNode(" Close stock modal ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseSpacer)),
            _createVNode(_unref(BaseButton), {
              color: "success",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (closeStockModal()))
            }, {
              default: _withCtx(() => _cache[36] || (_cache[36] = [
                _createTextVNode(" Close stock modal ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["parent-state"]),
        _createVNode(_unref(BaseSpacer), { size: "3" })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseContainer), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: '3xl' }
        }, {
          default: _withCtx(() => _cache[37] || (_cache[37] = [
            _createTextVNode("Text - Titles")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: '4xl' },
          color: "success"
        }, {
          default: _withCtx(() => _cache[38] || (_cache[38] = [
            _createTextVNode(" Title 4xl - Success ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: '3xl' },
          color: "warning"
        }, {
          default: _withCtx(() => _cache[39] || (_cache[39] = [
            _createTextVNode(" Title 3xl - Warning ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: '2xl' },
          color: "warning-alt"
        }, {
          default: _withCtx(() => _cache[40] || (_cache[40] = [
            _createTextVNode(" Title 2xl - Warning alt ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'xl' },
          color: "danger"
        }, {
          default: _withCtx(() => _cache[41] || (_cache[41] = [
            _createTextVNode("Title xl - Danger")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[42] || (_cache[42] = [
            _createTextVNode("Title lg")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'md' }
        }, {
          default: _withCtx(() => _cache[43] || (_cache[43] = [
            _createTextVNode("Title md")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), { tag: "h2" }, {
          default: _withCtx(() => _cache[44] || (_cache[44] = [
            _createTextVNode("Title base")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'sm' }
        }, {
          default: _withCtx(() => _cache[45] || (_cache[45] = [
            _createTextVNode("Title small")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer), { size: "3" }),
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: '3xl' }
        }, {
          default: _withCtx(() => _cache[46] || (_cache[46] = [
            _createTextVNode("Text - Body")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "p",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[47] || (_cache[47] = [
            _createTextVNode(" Text lg - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus scelerisque nulla ac ex finibus pulvinar. Integer aliquam urna ac nisl mattis, eu aliquam quam posuere. Sed quis sapien ante. Nunc accumsan mi a massa commodo, eu hendrerit mi porta. Mauris ultrices lorem id mollis sollicitudin. Cras feugiat leo feugiat ex sollicitudin, id dapibus metus laoreet. Aliquam ultricies lorem in justo ornare porta. Maecenas at orci ac leo tincidunt vulputate sed ut mi. Quisque nec magna tristique, mollis ipsum sed, convallis turpis. ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "p",
          size: { default: 'md' }
        }, {
          default: _withCtx(() => _cache[48] || (_cache[48] = [
            _createTextVNode(" Text md - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus scelerisque nulla ac ex finibus pulvinar. Integer aliquam urna ac nisl mattis, eu aliquam quam posuere. Sed quis sapien ante. Nunc accumsan mi a massa commodo, eu hendrerit mi porta. Mauris ultrices lorem id mollis sollicitudin. Cras feugiat leo feugiat ex sollicitudin, id dapibus metus laoreet. Aliquam ultricies lorem in justo ornare porta. Maecenas at orci ac leo tincidunt vulputate sed ut mi. Quisque nec magna tristique, mollis ipsum sed, convallis turpis. ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), { tag: "p" }, {
          default: _withCtx(() => _cache[49] || (_cache[49] = [
            _createTextVNode(" Text base - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus scelerisque nulla ac ex finibus pulvinar. Integer aliquam urna ac nisl mattis, eu aliquam quam posuere. Sed quis sapien ante. Nunc accumsan mi a massa commodo, eu hendrerit mi porta. Mauris ultrices lorem id mollis sollicitudin. Cras feugiat leo feugiat ex sollicitudin, id dapibus metus laoreet. Aliquam ultricies lorem in justo ornare porta. Maecenas at orci ac leo tincidunt vulputate sed ut mi. Quisque nec magna tristique, mollis ipsum sed, convallis turpis. ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "p",
          size: { default: 'sm' }
        }, {
          default: _withCtx(() => _cache[50] || (_cache[50] = [
            _createTextVNode(" Text base - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus scelerisque nulla ac ex finibus pulvinar. Integer aliquam urna ac nisl mattis, eu aliquam quam posuere. Sed quis sapien ante. Nunc accumsan mi a massa commodo, eu hendrerit mi porta. Mauris ultrices lorem id mollis sollicitudin. Cras feugiat leo feugiat ex sollicitudin, id dapibus metus laoreet. Aliquam ultricies lorem in justo ornare porta. Maecenas at orci ac leo tincidunt vulputate sed ut mi. Quisque nec magna tristique, mollis ipsum sed, convallis turpis. ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseContainer), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: '3xl' }
        }, {
          default: _withCtx(() => _cache[51] || (_cache[51] = [
            _createTextVNode("Grids")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[52] || (_cache[52] = [
            _createTextVNode("Grid - basic")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseGrid), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[53] || (_cache[53] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[54] || (_cache[54] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[55] || (_cache[55] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[56] || (_cache[56] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[57] || (_cache[57] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[58] || (_cache[58] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[59] || (_cache[59] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[60] || (_cache[60] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[61] || (_cache[61] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[62] || (_cache[62] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[63] || (_cache[63] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[64] || (_cache[64] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[65] || (_cache[65] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[66] || (_cache[66] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[67] || (_cache[67] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[68] || (_cache[68] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[69] || (_cache[69] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[70] || (_cache[70] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '3' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[71] || (_cache[71] = [
                _createTextVNode(" M: 6, T: 3 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '3' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[72] || (_cache[72] = [
                _createTextVNode(" M: 6, T: 3 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '3' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[73] || (_cache[73] = [
                _createTextVNode(" M: 6, T: 3 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '3' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[74] || (_cache[74] = [
                _createTextVNode(" M: 6, T: 3 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '4' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[75] || (_cache[75] = [
                _createTextVNode(" M: 12, T: 4 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '4' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[76] || (_cache[76] = [
                _createTextVNode(" M: 12, T: 4 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '4' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[77] || (_cache[77] = [
                _createTextVNode(" M: 12, T: 4 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '5' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[78] || (_cache[78] = [
                _createTextVNode(" M: 12, T: 5 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '7' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[79] || (_cache[79] = [
                _createTextVNode(" M: 12, T: 7 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '6' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[80] || (_cache[80] = [
                _createTextVNode(" M: 12, T: 6 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '6' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[81] || (_cache[81] = [
                _createTextVNode(" M: 12, T: 6 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '8' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[82] || (_cache[82] = [
                _createTextVNode(" M: 12, T: 8 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '4' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[83] || (_cache[83] = [
                _createTextVNode(" M: 12, T: 4 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '9' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[84] || (_cache[84] = [
                _createTextVNode(" M: 12, T: 9 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '3' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[85] || (_cache[85] = [
                _createTextVNode(" M: 12, T: 3 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '10' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[86] || (_cache[86] = [
                _createTextVNode(" M: 12, T: 10 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[87] || (_cache[87] = [
                _createTextVNode(" M: 12, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '11' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[88] || (_cache[88] = [
                _createTextVNode(" M: 12, T: 11 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[89] || (_cache[89] = [
                _createTextVNode(" M: 12, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { class: "bg-grey" }, {
              default: _withCtx(() => _cache[90] || (_cache[90] = [
                _createTextVNode(" M: 12, T: 12 ")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[91] || (_cache[91] = [
            _createTextVNode("Grid - gapless")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseGrid), { gap: "0" }, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[92] || (_cache[92] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[93] || (_cache[93] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[94] || (_cache[94] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[95] || (_cache[95] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[96] || (_cache[96] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[97] || (_cache[97] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[98] || (_cache[98] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[99] || (_cache[99] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[100] || (_cache[100] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[101] || (_cache[101] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[102] || (_cache[102] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[103] || (_cache[103] = [
                _createTextVNode(" M: 6, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[104] || (_cache[104] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[105] || (_cache[105] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[106] || (_cache[106] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[107] || (_cache[107] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[108] || (_cache[108] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[109] || (_cache[109] = [
                _createTextVNode(" M: 6, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '3' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[110] || (_cache[110] = [
                _createTextVNode(" M: 6, T: 3 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '3' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[111] || (_cache[111] = [
                _createTextVNode(" M: 6, T: 3 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '3' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[112] || (_cache[112] = [
                _createTextVNode(" M: 6, T: 3 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { default: '6', tablet: '3' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[113] || (_cache[113] = [
                _createTextVNode(" M: 6, T: 3 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '4' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[114] || (_cache[114] = [
                _createTextVNode(" M: 12, T: 4 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '4' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[115] || (_cache[115] = [
                _createTextVNode(" M: 12, T: 4 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '4' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[116] || (_cache[116] = [
                _createTextVNode(" M: 12, T: 4 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '5' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[117] || (_cache[117] = [
                _createTextVNode(" M: 12, T: 5 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '7' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[118] || (_cache[118] = [
                _createTextVNode(" M: 12, T: 7 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '6' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[119] || (_cache[119] = [
                _createTextVNode(" M: 12, T: 6 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '6' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[120] || (_cache[120] = [
                _createTextVNode(" M: 12, T: 6 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '8' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[121] || (_cache[121] = [
                _createTextVNode(" M: 12, T: 8 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '4' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[122] || (_cache[122] = [
                _createTextVNode(" M: 12, T: 4 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '9' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[123] || (_cache[123] = [
                _createTextVNode(" M: 12, T: 9 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '3' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[124] || (_cache[124] = [
                _createTextVNode(" M: 12, T: 3 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '10' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[125] || (_cache[125] = [
                _createTextVNode(" M: 12, T: 10 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '2' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[126] || (_cache[126] = [
                _createTextVNode(" M: 12, T: 2 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '11' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[127] || (_cache[127] = [
                _createTextVNode(" M: 12, T: 11 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), {
              span: { tablet: '1' },
              class: "bg-grey"
            }, {
              default: _withCtx(() => _cache[128] || (_cache[128] = [
                _createTextVNode(" M: 12, T: 1 ")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { class: "bg-grey" }, {
              default: _withCtx(() => _cache[129] || (_cache[129] = [
                _createTextVNode(" M: 12, T: 12 ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseContainer), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: '3xl' }
        }, {
          default: _withCtx(() => _cache[130] || (_cache[130] = [
            _createTextVNode("Buttons")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[131] || (_cache[131] = [
            _createTextVNode("Buttons - Submit")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseGrid), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "success",
                  type: "submit"
                }, {
                  default: _withCtx(() => _cache[132] || (_cache[132] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "warning",
                  type: "submit"
                }, {
                  default: _withCtx(() => _cache[133] || (_cache[133] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "warning-alt",
                  type: "submit"
                }, {
                  default: _withCtx(() => _cache[134] || (_cache[134] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "danger",
                  type: "submit"
                }, {
                  default: _withCtx(() => _cache[135] || (_cache[135] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), { type: "submit" }, {
                  default: _withCtx(() => _cache[136] || (_cache[136] = [
                    _createTextVNode("Button")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer), { size: "3" }),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[137] || (_cache[137] = [
            _createTextVNode("Buttons - button")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseGrid), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "success",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (console.log('clicked')))
                }, {
                  default: _withCtx(() => _cache[138] || (_cache[138] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "warning",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (console.log('clicked')))
                }, {
                  default: _withCtx(() => _cache[139] || (_cache[139] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "warning-alt",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (console.log('clicked')))
                }, {
                  default: _withCtx(() => _cache[140] || (_cache[140] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "danger",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (console.log('clicked')))
                }, {
                  default: _withCtx(() => _cache[141] || (_cache[141] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (console.log('clicked')))
                }, {
                  default: _withCtx(() => _cache[142] || (_cache[142] = [
                    _createTextVNode("Button")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer), { size: "3" }),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[143] || (_cache[143] = [
            _createTextVNode("Buttons - link internal")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseGrid), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  url: "/",
                  color: "success"
                }, {
                  default: _withCtx(() => _cache[144] || (_cache[144] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  url: "/",
                  color: "warning"
                }, {
                  default: _withCtx(() => _cache[145] || (_cache[145] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  url: "/",
                  color: "warning-alt"
                }, {
                  default: _withCtx(() => _cache[146] || (_cache[146] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  url: "/",
                  color: "danger"
                }, {
                  default: _withCtx(() => _cache[147] || (_cache[147] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), { url: "/" }, {
                  default: _withCtx(() => _cache[148] || (_cache[148] = [
                    _createTextVNode("Button")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer), { size: "3" }),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[149] || (_cache[149] = [
            _createTextVNode("Buttons - link external")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseGrid), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  url: "https://www.bbc.co.uk",
                  color: "success"
                }, {
                  default: _withCtx(() => _cache[150] || (_cache[150] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  url: "https://www.bbc.co.uk",
                  color: "warning"
                }, {
                  default: _withCtx(() => _cache[151] || (_cache[151] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  url: "https://www.bbc.co.uk",
                  color: "warning-alt"
                }, {
                  default: _withCtx(() => _cache[152] || (_cache[152] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  url: "https://www.bbc.co.uk",
                  color: "danger"
                }, {
                  default: _withCtx(() => _cache[153] || (_cache[153] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), { url: "https://www.bbc.co.uk" }, {
                  default: _withCtx(() => _cache[154] || (_cache[154] = [
                    _createTextVNode("Button")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer), { size: "3" }),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[155] || (_cache[155] = [
            _createTextVNode("Buttons - large")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseGrid), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "success",
                  size: "large"
                }, {
                  default: _withCtx(() => _cache[156] || (_cache[156] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "warning",
                  size: "large"
                }, {
                  default: _withCtx(() => _cache[157] || (_cache[157] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "warning-alt",
                  size: "large"
                }, {
                  default: _withCtx(() => _cache[158] || (_cache[158] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), {
                  color: "danger",
                  size: "large"
                }, {
                  default: _withCtx(() => _cache[159] || (_cache[159] = [
                    _createTextVNode(" Button ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(BaseColumn), { span: { tablet: '3' } }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseButton), { size: "large" }, {
                  default: _withCtx(() => _cache[160] || (_cache[160] = [
                    _createTextVNode("Button")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseContainer), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: '3xl' }
        }, {
          default: _withCtx(() => _cache[161] || (_cache[161] = [
            _createTextVNode("Forms")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseForm), { action: "/test" }, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseGrid), null, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseColumn), { span: { tablet: '6' } }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BaseInput), {
                      label: "First name",
                      name: "first-name",
                      value: form.firstName,
                      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((form.firstName) = $event)),
                      "onReset:errors": _cache[8] || (_cache[8] = ($event: any) => (form.errors.clear('first-name'))),
                      error: form.errors.get('first-name')
                    }, null, 8, ["value", "error"])
                  ]),
                  _: 1
                }),
                _createVNode(_unref(BaseColumn), { span: { tablet: '6' } }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BaseInput), {
                      label: "Last name",
                      name: "last-name",
                      value: form.lastName,
                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((form.lastName) = $event)),
                      "onReset:errors": _cache[10] || (_cache[10] = ($event: any) => (form.errors.clear('last-name'))),
                      error: form.errors.get('last-name')
                    }, null, 8, ["value", "error"])
                  ]),
                  _: 1
                }),
                _createVNode(_unref(BaseColumn), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BaseInput), {
                      label: "Message",
                      name: "message",
                      type: "textarea",
                      value: form.message,
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((form.message) = $event)),
                      "onReset:errors": _cache[12] || (_cache[12] = ($event: any) => (form.errors.clear('message'))),
                      error: form.errors.get('message')
                    }, null, 8, ["value", "error"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer), { size: "3" })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseContainer), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: '3xl' }
        }, {
          default: _withCtx(() => _cache[162] || (_cache[162] = [
            _createTextVNode("Alerts")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[163] || (_cache[163] = [
            _createTextVNode("Alert - Default")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseAlert), null, {
          default: _withCtx(() => _cache[164] || (_cache[164] = [
            _createTextVNode("This is an alert")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[165] || (_cache[165] = [
            _createTextVNode("Alert - Success")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseAlert), { color: "success" }, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseText), { tag: "p" }, {
              default: _withCtx(() => _cache[166] || (_cache[166] = [
                _createTextVNode("This is a multiline")
              ])),
              _: 1
            }),
            _createVNode(_unref(BaseText), { tag: "p" }, {
              default: _withCtx(() => _cache[167] || (_cache[167] = [
                _createTextVNode("Success message")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[168] || (_cache[168] = [
            _createTextVNode("Alert - warning")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseAlert), { color: "warning" }, {
          default: _withCtx(() => _cache[169] || (_cache[169] = [
            _createTextVNode("This is a warning")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), {
          tag: "h2",
          size: { default: 'lg' }
        }, {
          default: _withCtx(() => _cache[170] || (_cache[170] = [
            _createTextVNode("Alert - warning")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseAlert), { color: "danger" }, {
          default: _withCtx(() => _cache[171] || (_cache[171] = [
            _createTextVNode("This is danger")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer), { size: "3" })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseContainer), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: '3xl' }
        }, {
          default: _withCtx(() => _cache[172] || (_cache[172] = [
            _createTextVNode("Product card")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(ProductCard), {
          product: _unref(products)[0]
        }, null, 8, ["product"]),
        _createVNode(_unref(BaseSpacer), { size: "3" })
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseContainer), null, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseText), {
          tag: "h1",
          size: { default: '3xl' }
        }, {
          default: _withCtx(() => _cache[173] || (_cache[173] = [
            _createTextVNode("Product list")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(BaseText), { tag: "p" }, {
          default: _withCtx(() => _cache[174] || (_cache[174] = [
            _createTextVNode(" NOTE: Product list items will accept action colors (danger, warning, success) ")
          ])),
          _: 1
        }),
        _createVNode(_unref(BaseSpacer)),
        _createVNode(_unref(ProductList), { products: _unref(products) }, null, 8, ["products"])
      ]),
      _: 1
    })
  ], 64))
}
}

})