export const products = [
  {
    id: 'VR1bLQdqNg',
    createdAt: '2021-05-19T08:12:06.000000Z',
    updatedAt: '2021-05-19T08:12:56.000000Z',
    name: 'Kirsty Campbell',
    tokens: 41,
    features:
      'Suitable for children aged eight and up.|A great, educational game for all the family to enjoy.|Ideal for home, school or on the move.|Learn loads of fun facts about space and see how many you can recall in 10 seconds.|Play on your own, against a friend or in teams.',
    pageTitle: 'Ella Wilkinson',
    popularity: 1,
    description:
      "Do you know your comets from your constellations? Or your Saturn from your supernova? Here's your chance to test your memory and knowledge against the clock!|BrainBox Space is a great game for the whole family, where kids can learn interesting facts about life beyond Earth.|Simply look at the card and try to remember as many facts as you can in 10 seconds, then roll the die and see if you can answer the corresponding question.|The game is ideal for weekend fun, taking to school to test your friends or teachers, or even taking on a family holiday or to play while waiting at the dentist!|BrainBox games can be played individually, against a friend or in teams, so it's perfect for the whole family to get involved with. It's seriously addictive, and the kids will love proving they're smarter than the adults too!",
    recentRank: 22,
    metaKeywords:
      'Doloribus cupiditate voluptas amet beatae nostrum maiores. Deleniti fugiat sed quaerat unde beatae eaque suscipit. Est quam iste sint voluptate. Consequatur dolor facere in dicta exercitationem.',
    recommendedAge: 41,
    metaDescription:
      'Voluptas autem qui qui iure est quidem fugiat. Recusandae id velit exercitationem aperiam. Quo inventore vel quasi quae sed recusandae ea.',
    shortDescription:
      'Enim rerum eos tempora fuga sit assumenda nisi. Doloribus repellendus et in molestiae in. Eius possimus aut optio aliquam.',
    status: 1,
    publishedAt: 'null',
    gtin: '9788197157608',
    resourceType: 'products',
    highlights: [],
    route: {
      id: 'VR1bLQdqNg',
      createdAt: '2021-05-19T08:12:06.000000Z',
      updatedAt: '2021-05-19T08:12:06.000000Z',
      default: 1,
      redirect: 0,
      slug: 'kirsty-campbell',
      locale: 'en',
      description: null,
      path: null,
    },
    sku: '9785072484149', //@Todo: Replace this with real data
    productVariants: [
      {
        id: 'VR1bLQdqNg',
        createdAt: '2021-05-19T08:12:06.000000Z',
        updatedAt: '2021-05-19T08:12:56.000000Z',
        sku: '9785072484149',
        options: null,
        price: '0.00000',
        unitQty: 0,
        minQty: 0,
        minBatch: 0,
        maxQty: 0,
        incoming: 0,
        backorder: 'in-stock',
        requiresShipping: true,
        weightValue: '75.76420',
        weightUnit: 'kg',
        heightValue: '80.70385',
        heightUnit: 'cm',
        widthValue: '85.20000',
        widthUnit: 'cm',
        depthValue: '19.34720',
        depthUnit: 'cm',
        volumeValue: '68.18943',
        volumeUnit: 'l',
        taxId: null,
        groupPricing: false,
        batteriesRequired: true,
        otherHazards: null,
        numberOfPieces: 82,
        supplierName: 'Paul Walker',
        supplierSku: '9796353066879',
        supplierTradePrice: '80.00',
        supplierTokenMargin: 41,
        boxSizeId: 1,
        aggregateRating: null,
        resourceType: 'product_variants',
        highlights: [],
        accountedForReviews: [],
        totalReviews: 0,
        ratings: { '1Star': 0, '2Star': 0, '3Star': 0, '4Star': 0, '5Star': 0 },
        stock: 6,
      },
    ],
    assets: [
      {
        id: '9lVbJXPv7q',
        createdAt: '2021-05-19T08:12:06.000000Z',
        updatedAt: '2021-05-19T08:12:52.000000Z',
        position: 1,
        location: 'products/VR1bLQdqNg',
        primary: true,
        kind: 'image',
        subKind: 'jpeg',
        width: '10',
        height: '10',
        title: 'image-1.jpg',
        originalFilename: 'image-1.jpg',
        caption: null,
        size: 695,
        external: false,
        extension: 'jpg',
        filename: 'zBbVk2vftA_kirsty-campbell.jpg',
        transforms: [
          {
            id: '50RdXN2Wqo',
            createdAt: '2021-05-19T08:12:06.000000Z',
            updatedAt: '2021-05-19T08:12:06.000000Z',
            fileExists: true,
            location: 'products/VR1bLQdqNg',
            filename: 'zBbVk2vftA_kirsty-campbell_thumbnail.jpg',
            size: 'thumbnail',
            url: 'https://whirli-api.test/storage/products/VR1bLQdqNg/zBbVk2vftA_kirsty-campbell_thumbnail.jpg',
          },
          {
            id: '8Z4PDRLb02',
            createdAt: '2021-05-19T08:12:06.000000Z',
            updatedAt: '2021-05-19T08:12:06.000000Z',
            fileExists: true,
            location: 'products/VR1bLQdqNg',
            filename: 'zBbVk2vftA_kirsty-campbell_large_thumbnail.jpg',
            size: 'large_thumbnail',
            url: 'https://whirli-api.test/storage/products/VR1bLQdqNg/zBbVk2vftA_kirsty-campbell_large_thumbnail.jpg',
          },
        ],
        thumbnail: {
          id: '50RdXN2Wqo',
          createdAt: '2021-05-19T08:12:06.000000Z',
          updatedAt: '2021-05-19T08:12:06.000000Z',
          fileExists: true,
          location: 'products/VR1bLQdqNg',
          filename: 'zBbVk2vftA_kirsty-campbell_thumbnail.jpg',
          size: 'thumbnail',
          url: 'https://whirli-api.test/storage/products/VR1bLQdqNg/zBbVk2vftA_kirsty-campbell_thumbnail.jpg',
        },
        largeThumbnail: {
          id: '8Z4PDRLb02',
          createdAt: '2021-05-19T08:12:06.000000Z',
          updatedAt: '2021-05-19T08:12:06.000000Z',
          fileExists: true,
          location: 'products/VR1bLQdqNg',
          filename: 'zBbVk2vftA_kirsty-campbell_large_thumbnail.jpg',
          size: 'large_thumbnail',
          url: 'https://whirli-api.test/storage/products/VR1bLQdqNg/zBbVk2vftA_kirsty-campbell_large_thumbnail.jpg',
        },
        mailchimpThumbnail: null,
        url: 'https://whirli-api.test/storage/products/VR1bLQdqNg/zBbVk2vftA_kirsty-campbell.jpg',
      },
      {
        id: '5DzPkoPR92',
        createdAt: '2021-05-19T08:12:06.000000Z',
        updatedAt: '2021-05-19T08:12:52.000000Z',
        position: 2,
        location: 'products/VR1bLQdqNg',
        primary: false,
        kind: 'image',
        subKind: 'jpeg',
        width: '10',
        height: '10',
        title: 'image-2.jpg',
        originalFilename: 'image-2.jpg',
        caption: null,
        size: 695,
        external: false,
        extension: 'jpg',
        filename: 'vHXOnB21dH_kirsty-campbell.jpg',
        transforms: [
          {
            id: 'z0qbm6vdM2',
            createdAt: '2021-05-19T08:12:06.000000Z',
            updatedAt: '2021-05-19T08:12:06.000000Z',
            fileExists: true,
            location: 'products/VR1bLQdqNg',
            filename: 'vHXOnB21dH_kirsty-campbell_thumbnail.jpg',
            size: 'thumbnail',
            url: 'https://whirli-api.test/storage/products/VR1bLQdqNg/vHXOnB21dH_kirsty-campbell_thumbnail.jpg',
          },
          {
            id: 'Z98PZ4xbG6',
            createdAt: '2021-05-19T08:12:06.000000Z',
            updatedAt: '2021-05-19T08:12:06.000000Z',
            fileExists: true,
            location: 'products/VR1bLQdqNg',
            filename: 'vHXOnB21dH_kirsty-campbell_large_thumbnail.jpg',
            size: 'large_thumbnail',
            url: 'https://whirli-api.test/storage/products/VR1bLQdqNg/vHXOnB21dH_kirsty-campbell_large_thumbnail.jpg',
          },
        ],
        thumbnail: {
          id: 'z0qbm6vdM2',
          createdAt: '2021-05-19T08:12:06.000000Z',
          updatedAt: '2021-05-19T08:12:06.000000Z',
          fileExists: true,
          location: 'products/VR1bLQdqNg',
          filename: 'vHXOnB21dH_kirsty-campbell_thumbnail.jpg',
          size: 'thumbnail',
          url: 'https://whirli-api.test/storage/products/VR1bLQdqNg/vHXOnB21dH_kirsty-campbell_thumbnail.jpg',
        },
        largeThumbnail: {
          id: 'Z98PZ4xbG6',
          createdAt: '2021-05-19T08:12:06.000000Z',
          updatedAt: '2021-05-19T08:12:06.000000Z',
          fileExists: true,
          location: 'products/VR1bLQdqNg',
          filename: 'vHXOnB21dH_kirsty-campbell_large_thumbnail.jpg',
          size: 'large_thumbnail',
          url: 'https://whirli-api.test/storage/products/VR1bLQdqNg/vHXOnB21dH_kirsty-campbell_large_thumbnail.jpg',
        },
        mailchimpThumbnail: null,
        url: 'https://whirli-api.test/storage/products/VR1bLQdqNg/vHXOnB21dH_kirsty-campbell.jpg',
      },
    ],
    slug: 'kirsty-campbell',
    stock: 6,
  },
  {
    id: 'l4gPOVdw0J',
    createdAt: '2021-05-19T08:12:06.000000Z',
    updatedAt: '2021-05-19T08:12:56.000000Z',
    name: 'John Simpson',
    tokens: 37,
    features:
      'Suitable for children aged eight and up.|A great, educational game for all the family to enjoy.|Ideal for home, school or on the move.|Learn loads of fun facts about space and see how many you can recall in 10 seconds.|Play on your own, against a friend or in teams.',
    pageTitle: 'Frank Robinson',
    popularity: 8,
    description:
      "Do you know your comets from your constellations? Or your Saturn from your supernova? Here's your chance to test your memory and knowledge against the clock!|BrainBox Space is a great game for the whole family, where kids can learn interesting facts about life beyond Earth.|Simply look at the card and try to remember as many facts as you can in 10 seconds, then roll the die and see if you can answer the corresponding question.|The game is ideal for weekend fun, taking to school to test your friends or teachers, or even taking on a family holiday or to play while waiting at the dentist!|BrainBox games can be played individually, against a friend or in teams, so it's perfect for the whole family to get involved with. It's seriously addictive, and the kids will love proving they're smarter than the adults too!",
    recentRank: 5,
    metaKeywords:
      'Nesciunt soluta odio consequatur dolores non. Delectus illum voluptas rerum rerum ut fuga dignissimos expedita. Nesciunt deserunt aut quis omnis tempora eum.',
    recommendedAge: 28,
    metaDescription:
      'Veritatis illum ut impedit et ex libero. Illum assumenda quod ipsa beatae. Eos inventore dolores dolores in earum.',
    shortDescription:
      'Voluptas necessitatibus eaque ad quas qui. Sed illo sunt laborum dolores et culpa qui. Deleniti ut maxime quia incidunt molestiae id.',
    status: 1,
    publishedAt: 'null',
    gtin: '9786757130757',
    resourceType: 'products',
    highlights: [],
    route: {
      id: 'l4gPOVdw0J',
      createdAt: '2021-05-19T08:12:06.000000Z',
      updatedAt: '2021-05-19T08:12:06.000000Z',
      default: 1,
      redirect: 0,
      slug: 'john-simpson',
      locale: 'en',
      description: null,
      path: null,
    },
    productVariants: [
      {
        id: 'l4gPOVdw0J',
        createdAt: '2021-05-19T08:12:06.000000Z',
        updatedAt: '2021-05-19T08:12:56.000000Z',
        sku: '9789909939864',
        options: null,
        price: '0.00000',
        unitQty: 0,
        minQty: 0,
        minBatch: 0,
        maxQty: 0,
        incoming: 0,
        backorder: 'in-stock',
        requiresShipping: true,
        weightValue: '53.29502',
        weightUnit: 'kg',
        heightValue: '98.47000',
        heightUnit: 'cm',
        widthValue: '60.68454',
        widthUnit: 'cm',
        depthValue: '72.65335',
        depthUnit: 'cm',
        volumeValue: '28.53400',
        volumeUnit: 'l',
        taxId: null,
        groupPricing: false,
        batteriesRequired: true,
        otherHazards: null,
        numberOfPieces: 54,
        supplierName: 'Florence Fox',
        supplierSku: '9798658341484',
        supplierTradePrice: '4.00',
        supplierTokenMargin: 19,
        boxSizeId: 2,
        aggregateRating: null,
        resourceType: 'product_variants',
        highlights: [],
        accountedForReviews: [],
        totalReviews: 0,
        ratings: { '1Star': 0, '2Star': 0, '3Star': 0, '4Star': 0, '5Star': 0 },
        stock: 6,
      },
    ],
    assets: [
      {
        id: 'KY3bR6Qbpk',
        createdAt: '2021-05-19T08:12:07.000000Z',
        updatedAt: '2021-05-19T08:12:52.000000Z',
        position: 1,
        location: 'products/l4gPOVdw0J',
        primary: true,
        kind: 'image',
        subKind: 'jpeg',
        width: '10',
        height: '10',
        title: 'image-1.jpg',
        originalFilename: 'image-1.jpg',
        caption: null,
        size: 695,
        external: false,
        extension: 'jpg',
        filename: 'to9AkLvePq_john-simpson.jpg',
        transforms: [
          {
            id: 'ArDW2Y3P6Y',
            createdAt: '2021-05-19T08:12:07.000000Z',
            updatedAt: '2021-05-19T08:12:07.000000Z',
            fileExists: true,
            location: 'products/l4gPOVdw0J',
            filename: 'to9AkLvePq_john-simpson_thumbnail.jpg',
            size: 'thumbnail',
            url: 'https://whirli-api.test/storage/products/l4gPOVdw0J/to9AkLvePq_john-simpson_thumbnail.jpg',
          },
          {
            id: 'KY3bRR7bpk',
            createdAt: '2021-05-19T08:12:07.000000Z',
            updatedAt: '2021-05-19T08:12:07.000000Z',
            fileExists: true,
            location: 'products/l4gPOVdw0J',
            filename: 'to9AkLvePq_john-simpson_large_thumbnail.jpg',
            size: 'large_thumbnail',
            url: 'https://whirli-api.test/storage/products/l4gPOVdw0J/to9AkLvePq_john-simpson_large_thumbnail.jpg',
          },
        ],
        thumbnail: {
          id: 'ArDW2Y3P6Y',
          createdAt: '2021-05-19T08:12:07.000000Z',
          updatedAt: '2021-05-19T08:12:07.000000Z',
          fileExists: true,
          location: 'products/l4gPOVdw0J',
          filename: 'to9AkLvePq_john-simpson_thumbnail.jpg',
          size: 'thumbnail',
          url: 'https://whirli-api.test/storage/products/l4gPOVdw0J/to9AkLvePq_john-simpson_thumbnail.jpg',
        },
        largeThumbnail: {
          id: 'KY3bRR7bpk',
          createdAt: '2021-05-19T08:12:07.000000Z',
          updatedAt: '2021-05-19T08:12:07.000000Z',
          fileExists: true,
          location: 'products/l4gPOVdw0J',
          filename: 'to9AkLvePq_john-simpson_large_thumbnail.jpg',
          size: 'large_thumbnail',
          url: 'https://whirli-api.test/storage/products/l4gPOVdw0J/to9AkLvePq_john-simpson_large_thumbnail.jpg',
        },
        mailchimpThumbnail: null,
        url: 'https://whirli-api.test/storage/products/l4gPOVdw0J/to9AkLvePq_john-simpson.jpg',
      },
      {
        id: 'l4gPORVdw0',
        createdAt: '2021-05-19T08:12:07.000000Z',
        updatedAt: '2021-05-19T08:12:52.000000Z',
        position: 2,
        location: 'products/l4gPOVdw0J',
        primary: false,
        kind: 'image',
        subKind: 'jpeg',
        width: '10',
        height: '10',
        title: 'image-2.jpg',
        originalFilename: 'image-2.jpg',
        caption: null,
        size: 695,
        external: false,
        extension: 'jpg',
        filename: '6Jrk8WJCHe_john-simpson.jpg',
        transforms: [
          {
            id: 'l4gPOYZbw0',
            createdAt: '2021-05-19T08:12:07.000000Z',
            updatedAt: '2021-05-19T08:12:07.000000Z',
            fileExists: true,
            location: 'products/l4gPOVdw0J',
            filename: '6Jrk8WJCHe_john-simpson_thumbnail.jpg',
            size: 'thumbnail',
            url: 'https://whirli-api.test/storage/products/l4gPOVdw0J/6Jrk8WJCHe_john-simpson_thumbnail.jpg',
          },
          {
            id: 'JMndE53dgQ',
            createdAt: '2021-05-19T08:12:07.000000Z',
            updatedAt: '2021-05-19T08:12:07.000000Z',
            fileExists: true,
            location: 'products/l4gPOVdw0J',
            filename: '6Jrk8WJCHe_john-simpson_large_thumbnail.jpg',
            size: 'large_thumbnail',
            url: 'https://whirli-api.test/storage/products/l4gPOVdw0J/6Jrk8WJCHe_john-simpson_large_thumbnail.jpg',
          },
        ],
        thumbnail: {
          id: 'l4gPOYZbw0',
          createdAt: '2021-05-19T08:12:07.000000Z',
          updatedAt: '2021-05-19T08:12:07.000000Z',
          fileExists: true,
          location: 'products/l4gPOVdw0J',
          filename: '6Jrk8WJCHe_john-simpson_thumbnail.jpg',
          size: 'thumbnail',
          url: 'https://whirli-api.test/storage/products/l4gPOVdw0J/6Jrk8WJCHe_john-simpson_thumbnail.jpg',
        },
        largeThumbnail: {
          id: 'JMndE53dgQ',
          createdAt: '2021-05-19T08:12:07.000000Z',
          updatedAt: '2021-05-19T08:12:07.000000Z',
          fileExists: true,
          location: 'products/l4gPOVdw0J',
          filename: '6Jrk8WJCHe_john-simpson_large_thumbnail.jpg',
          size: 'large_thumbnail',
          url: 'https://whirli-api.test/storage/products/l4gPOVdw0J/6Jrk8WJCHe_john-simpson_large_thumbnail.jpg',
        },
        mailchimpThumbnail: null,
        url: 'https://whirli-api.test/storage/products/l4gPOVdw0J/6Jrk8WJCHe_john-simpson.jpg',
      },
    ],
    slug: 'john-simpson',
    stock: 6,
  },
];
