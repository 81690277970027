import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { useStore } from 'vuex';

// Components - Import
import { BaseContainer, BaseColumn, BaseGrid } from '@/components/primitives';
import { BaseButton, BaseSpacer } from '@/components/partials';

// @use - Import
import useEnvironment from '@/@use/environment';

// @use - Envoke

export default /*@__PURE__*/_defineComponent({
  __name: 'Dashboard',
  setup(__props) {

// Vue - Import
const store = useStore();

// const { isDevelopmentMode } = useEnvironment();
const isDevelopmentMode = !window.location.origin.startsWith('https://wops.whirli.com'); //Remove when production mode updated

store.dispatch('page/setReference', '', { root: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseSpacer), { size: "4" }),
      _createVNode(_unref(BaseGrid), null, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), {
            span: { default: '6' },
            class: "dashboard"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                url: "/inbound",
                size: "large",
                class: "dashboard__icon dashboard__icon_inbound",
                "data-theme": "quaternary",
                ref: "inbound"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Inbound ")
                ])),
                _: 1
              }, 512)
            ]),
            _: 1
          }),
          isDevelopmentMode
            ? (_openBlock(), _createBlock(_unref(BaseColumn), {
                key: 0,
                span: { default: '6' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseButton), {
                    url: "/stock",
                    size: "large",
                    class: "dashboard__icon dashboard__icon_stock",
                    "data-theme": "secondary",
                    ref: "stock"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" Stock ")
                    ])),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_unref(BaseColumn), { span: { default: '6' } }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                url: "/picking",
                size: "large",
                class: "dashboard__icon dashboard__icon_picking",
                "data-theme": "primary",
                ref: "picking"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Picking ")
                ])),
                _: 1
              }, 512)
            ]),
            _: 1
          }),
          _createVNode(_unref(BaseColumn), { span: { default: '6' } }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                url: "/packing",
                size: "large",
                class: "dashboard__icon dashboard__icon_packing",
                "data-theme": "tertiary",
                ref: "packing"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Packing ")
                ])),
                _: 1
              }, 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})